var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._l(_vm.descriptionAndTitle, function (element) {
    return _c('div', {
      key: element.id,
      staticClass: "bg-white p-4 text-gray-500 my-4 rounded-lg shadow-sm"
    }, [element.id ? _c('span', {
      staticClass: "py-3 text-gray-800"
    }, [_vm._v(" Content Recommendation "), _c('i', {
      staticClass: "fa fa-angle-right",
      attrs: {
        "aria-hidden": "true"
      }
    }), _vm._v(" " + _vm._s(element.title) + " "), _c('h5', {
      staticClass: "mt-1 text-2xl"
    }, [_vm._v(_vm._s(element.title))])]) : _vm._e()]);
  }), _c('div', {
    staticClass: "flex flex-wrap justify-between px-4 pt-4 rounded-lg items-center bg-white shadow-sm"
  }, [_c('div', {
    staticClass: "flex w-full justify-between items-center"
  }, [_c('h5', {
    staticClass: "mt-2 mb-2 flex text-xl w-full text-left"
  }, [_vm._v(" Content Recommendation Details")]), _c('button', {
    staticClass: "bg-blue-500 text-white px-2 py-1 rounded-lg font-semibold"
  }, [_c('div', {
    staticClass: "flex justify-between align-middle items-center"
  }, [_c('svg', {
    staticClass: "w-4 h-4 mr-1 text-white dark:text-white",
    attrs: {
      "aria-hidden": "true",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "currentColor",
      "viewBox": "0 0 24 24"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "d": "M11.32 6.176H5c-1.105 0-2 .949-2 2.118v10.588C3 20.052 3.895 21 5 21h11c1.105 0 2-.948 2-2.118v-7.75l-3.914 4.144A2.46 2.46 0 0 1 12.81 16l-2.681.568c-1.75.37-3.292-1.263-2.942-3.115l.536-2.839c.097-.512.335-.983.684-1.352l2.914-3.086Z",
      "clip-rule": "evenodd"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "d": "M19.846 4.318a2.148 2.148 0 0 0-.437-.692 2.014 2.014 0 0 0-.654-.463 1.92 1.92 0 0 0-1.544 0 2.014 2.014 0 0 0-.654.463l-.546.578 2.852 3.02.546-.579a2.14 2.14 0 0 0 .437-.692 2.244 2.244 0 0 0 0-1.635ZM17.45 8.721 14.597 5.7 9.82 10.76a.54.54 0 0 0-.137.27l-.536 2.84c-.07.37.239.696.588.622l2.682-.567a.492.492 0 0 0 .255-.145l4.778-5.06Z",
      "clip-rule": "evenodd"
    }
  })]), _c('span', [_c('router-link', {
    staticClass: "text-white",
    attrs: {
      "to": {
        path: ("/content-recommedation/edit/" + (this.$route.params.id))
      }
    }
  }, [_vm._v(" Edit ")])], 1)])])]), _vm._l(_vm.descriptionAndTitle, function (element) {
    var _element$description, _vm$formatDate, _vm$formatDate2;
    return _c('div', {
      key: element.id,
      staticClass: "flex w-full",
      class: ['transition-max-height duration-300 ease-in-out overflow-hidden', _vm.readMore ? 'min-h-max' : 'h-36']
    }, [_c('div', {
      staticClass: "w-1/2 flex flex-col mr-2"
    }, [_c('span', {
      staticClass: "font-bold mb-1"
    }, [_vm._v("Section title")]), _c('p', {
      staticClass: "mb-1 text-gray-500"
    }, [_vm._v(_vm._s(element.title))]), _c('label', {
      staticClass: "font-bold"
    }, [_vm._v("Description")]), _c('p', {
      staticClass: "text-gray-500"
    }, [_vm._v(" " + _vm._s((_element$description = element.description) !== null && _element$description !== void 0 ? _element$description : '-') + " ")])]), _c('div', {
      staticClass: "w-1/2 ml-2 flex flex-col"
    }, [_c('p', {
      staticClass: "mb-1 font-bold"
    }, [_vm._v("Created Date")]), _c('span', {
      staticClass: "text-gray-500"
    }, [_vm._v(" " + _vm._s((_vm$formatDate = _vm.formatDate(element.created)) !== null && _vm$formatDate !== void 0 ? _vm$formatDate : '-'))]), _c('p', {
      staticClass: "mb-1 mt-2 font-bold"
    }, [_vm._v("Last Modified Date")]), _c('span', {
      staticClass: "text-gray-500"
    }, [_vm._v(" " + _vm._s((_vm$formatDate2 = _vm.formatDate(element.modified)) !== null && _vm$formatDate2 !== void 0 ? _vm$formatDate2 : '-'))])])]);
  }), _c('div', {
    staticClass: "flex w-full text-center justify-center pb-4 pt-2"
  }, [_c('div', {
    staticClass: "text-gray-700 flex items-center font-bold text-sm cursor-pointer",
    on: {
      "click": _vm.toggleReadMore
    }
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.readMore ? 'Read less' : 'Read more') + " ")]), !_vm.readMore ? _c('svg', {
    staticClass: "w-4 h-4 ml-1 text-gray-800 dark:text-white",
    attrs: {
      "aria-hidden": "true",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "viewBox": "0 0 24 24"
    }
  }, [_c('path', {
    attrs: {
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "d": "m19 9-7 7-7-7"
    }
  })]) : _c('svg', {
    staticClass: "w-4 h-4 ml-1 text-gray-800 dark:text-white",
    attrs: {
      "aria-hidden": "true",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "viewBox": "0 0 24 24"
    }
  }, [_c('path', {
    attrs: {
      "stroke": "currentColor",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "stroke-width": "2",
      "d": "m5 15 7-7 7 7"
    }
  })])])])], 2), _c('div', {
    staticClass: "mt-4 pt-2 pb-2 rounded-lg bg-white shadow-sm"
  }, [_c('Toast'), _c('ModalDeleteContent', {
    attrs: {
      "isModalVisibleDelete": _vm.showModalDelete,
      "isOpenDelete": _vm.showModalDelete
    },
    on: {
      "update:isModalVisibleDelete": function ($event) {
        _vm.showModalDelete = $event;
      },
      "update:is-modal-visible-delete": function ($event) {
        _vm.showModalDelete = $event;
      },
      "close": _vm.closeModal,
      "confirm": _vm.confirmDelete
    }
  }), _c('div', {
    staticClass: "flex justify-between items-center my-4 px-4"
  }, [_vm._m(0), _c('div', {
    staticClass: "w-1/2 px-3 flex justify-end"
  }, [!_vm.triggerSort ? _c('button', {
    staticClass: "rounded-lg border-gray-200 p-2 border focus:outline-none relative right text-gray-500 bg-gray-white",
    on: {
      "click": _vm.triggerSortBtn
    }
  }, [_c('i', {
    staticClass: "fa fa-list",
    attrs: {
      "aria-hidden": "true"
    }
  }), _vm._v(" Reorder Content ")]) : _vm._e(), _vm.triggerSort ? _c('button', {
    staticClass: "rounded-lg border p-2 focus:outline-none relative text-white bg-blue-500",
    on: {
      "click": _vm.submitAndSave
    }
  }, [_vm._v("Save & Update ")]) : _vm._e(), _vm.triggerSort ? _c('button', {
    staticClass: "rounded-lg ml-3 p-2 focus:outline-none relative text-gray-500 bg-white border",
    on: {
      "click": _vm.handleCancelSort
    }
  }, [_vm._v("Cancel")]) : _vm._e()])]), _vm.isLoading ? _c('div', {
    staticClass: "mt-5 flex justify-center"
  }, [_c('div', {
    staticClass: "text-center text-dark my-2 w-full bg-white",
    staticStyle: {
      "height": "600px",
      "background-color": "rgb(204 204 204 / 39%)",
      "display": "flex",
      "border-radius": "5px",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('div', {
    staticStyle: {
      "transform": "translate(-50%, -50%)"
    }
  }, [_c('b-spinner', {
    staticClass: "align-middle",
    attrs: {
      "show": _vm.isLoading,
      "variant": "dark",
      "type": "grow",
      "label": "Loading .."
    }
  }), _vm.isLoading ? _c('span', [_vm._v("Loading ..")]) : _vm._e()], 1)])]) : _c('div', {
    staticClass: "px-4 mb-4 relative rounded-lg"
  }, [_c('div', {
    staticClass: "relative parent-table overflow-x-auto shadow-md sm:rounded-lg scrollbar parent-table",
    attrs: {
      "id": "style-1"
    }
  }, [_c('table', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isLoading,
      expression: "!isLoading"
    }],
    staticClass: "w-full h-[400px] border-r border-l text-sm text-gray-500 text-left rtl:text-right bg-white dark:text-gray-400 drag-move rounded-lg"
  }, [_vm._m(1), _vm.items.length > 0 ? _c('draggable', _vm._b({
    class: _vm.triggerSort ? 'cursor-move showBtn' : 'pointer-events-none',
    attrs: {
      "element": "tbody",
      "items": _vm.items,
      "move": _vm.checkMove,
      "disabled": !_vm.enabled,
      "ghost-class": "ghost"
    },
    on: {
      "start": _vm.handleDragStart,
      "end": function ($event) {
        return _vm.handleDragEnd($event);
      }
    }
  }, 'draggable', _vm.dragOptions, false), _vm._l(_vm.items, function (element, index) {
    return _c('tr', {
      key: element.id,
      attrs: {
        "data-item-id": element.item_id
      }
    }, [_c('td', {
      staticClass: "px-6 py-3",
      attrs: {
        "scope": "col"
      }
    }, [_c('span', {
      class: {
        'block': _vm.triggerSort,
        'hidden': !_vm.triggerSort
      }
    }, [_c('svg', {
      attrs: {
        "width": "12",
        "height": "14",
        "viewBox": "0 0 12 14",
        "fill": "none",
        "xmlns": "http://www.w3.org/2000/svg"
      }
    }, [_c('path', {
      attrs: {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        "d": "M0.879883 4.44001C0.879883 4.08654 1.16642 3.80001 1.51988 3.80001H10.4799C10.8333 3.80001 11.1199 4.08654 11.1199 4.44001C11.1199 4.79347 10.8333 5.08001 10.4799 5.08001H1.51988C1.16642 5.08001 0.879883 4.79347 0.879883 4.44001ZM0.879883 7.00001C0.879883 6.64654 1.16642 6.36001 1.51988 6.36001H10.4799C10.8333 6.36001 11.1199 6.64654 11.1199 7.00001C11.1199 7.35347 10.8333 7.64001 10.4799 7.64001H1.51988C1.16642 7.64001 0.879883 7.35347 0.879883 7.00001ZM0.879883 9.56001C0.879883 9.20654 1.16642 8.92001 1.51988 8.92001H10.4799C10.8333 8.92001 11.1199 9.20654 11.1199 9.56001C11.1199 9.91347 10.8333 10.2 10.4799 10.2H1.51988C1.16642 10.2 0.879883 9.91347 0.879883 9.56001Z",
        "fill": "#6B7280"
      }
    }), _c('path', {
      attrs: {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        "d": "M5.74981 0.716474C5.9051 0.561184 6.15688 0.561184 6.31217 0.716474L7.43687 1.84118C7.59216 1.99647 7.59216 2.24825 7.43687 2.40354C7.28158 2.55883 7.02981 2.55883 6.87452 2.40354L6.03099 1.56001L5.18746 2.40354C5.03217 2.55883 4.78039 2.55883 4.6251 2.40354C4.46981 2.24825 4.46981 1.99647 4.6251 1.84118L5.74981 0.716474Z",
        "fill": "#6B7280"
      }
    }), _c('path', {
      attrs: {
        "fill-rule": "evenodd",
        "clip-rule": "evenodd",
        "d": "M6.31246 13.2835C6.15717 13.4388 5.90539 13.4388 5.7501 13.2835L4.62539 12.1588C4.4701 12.0035 4.4701 11.7518 4.62539 11.5965C4.78068 11.4412 5.03246 11.4412 5.18775 11.5965L6.03128 12.44L6.87481 11.5965C7.0301 11.4412 7.28188 11.4412 7.43717 11.5965C7.59246 11.7518 7.59246 12.0035 7.43717 12.1588L6.31246 13.2835Z",
        "fill": "#6B7280"
      }
    })])])]), _c('td', {
      staticClass: "px-6 py-3",
      attrs: {
        "scope": "col"
      }
    }, [_vm._v(_vm._s(index + 1))]), _c('td', {
      staticClass: "px-6 py-3",
      attrs: {
        "scope": "col"
      }
    }, [_vm._v(_vm._s(element.item_type))]), _c('td', {
      staticClass: "px-6 py-3",
      attrs: {
        "scope": "col"
      }
    }, [_vm._v(_vm._s(element.item_id))]), _c('td', {
      staticClass: "px-6 py-3",
      attrs: {
        "scope": "col"
      }
    }, [_vm._v(_vm._s(element.item_name))]), _c('td', [_c('span', {
      class: {
        'block': _vm.triggerSort,
        'hidden': !_vm.triggerSort
      },
      on: {
        "click": function ($event) {
          return _vm.removeItem(element, index);
        }
      }
    }, [_c('i', {
      staticClass: "fa fa-trash text-gray-500 cursor-pointer",
      staticStyle: {
        "font-size": "20px"
      },
      attrs: {
        "aria-hidden": "true"
      }
    })])])]);
  }), 0) : _c('tbody', [_c('tr', [_c('td', {
    staticClass: "text-center",
    attrs: {
      "colspan": "6"
    }
  }, [_vm._v("No items available")])])])], 1)])])], 1)], 2);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-1/2 px-1"
  }, [_c('label', {
    staticClass: "font-bold text-xl text-gray-700"
  }, [_vm._v("All Contents Under this Section Title")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', {
    staticClass: "px-6 py-3",
    attrs: {
      "scope": "col"
    }
  }), _c('th', {
    staticClass: "px-6 py-3 text-gray-500",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("No")]), _c('th', {
    staticClass: "px-6 py-3 text-gray-500",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("CONTENT TYPE")]), _c('th', {
    staticClass: "px-6 py-3 text-gray-500",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("CONTENT ID")]), _c('th', {
    staticClass: "px-6 py-3 text-gray-500",
    attrs: {
      "scope": "col"
    }
  }, [_vm._v("CONTENT NAME")]), _c('th')])]);
}]

export { render, staticRenderFns }